import React, {  useState } from "react";

import Section from "./section";
import './index1.css';

const Index1 = () => {

  return (
    <React.Fragment>

      <Section />


    </React.Fragment>
  );
};

export default Index1;
